<template>
  <c-box
    height="calc(100vh - 84px)"
    display="flex"
    align-items="center"
    justify-content="center"
    width="100%"
  >
    <c-box
      display="flex"
      flex-direction="column"
      align-items="center"
      gap="30px"
      text-align="center"
    >
      <c-image
        :width="['150px', '300px']"
        src="https://ik.imagekit.io/dietela/pwa_webp/popup/notfound404.png?updatedAt=1690890254800"
        alt="image page not found"
      />
      <BaseText
        size-mobile="20px"
        size-desktop="28px"
        color="primary.400"
      >
        404 Not Found
      </BaseText>
      <BaseText
        size-mobile="12px"
        size-desktop="16px"
      >
        Oops! Halaman ini tidak tersedia. <br>
        Silahkan kembali ke halaman sebelumnya ya!
      </BaseText>
      <BaseButton
        size="medium"
        border-radius="1000px"
        width="100%"
        :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
        right-svg-icon-color="white"
        @click="$router.back()"
      >
        Kembali
      </BaseButton>
    </c-box>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'PageNotFound',
  components: { BaseButton, BaseText },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "height": "calc(100vh - 84px)",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center",
      "gap": "30px",
      "text-align": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "width": ['150px', '300px'],
      "src": "https://ik.imagekit.io/dietela/pwa_webp/popup/notfound404.png?updatedAt=1690890254800",
      "alt": "image page not found"
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "color": "primary.400"
    }
  }, [_vm._v(" 404 Not Found ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Oops! Halaman ini tidak tersedia. "), _c('br'), _vm._v(" Silahkan kembali ke halaman sebelumnya ya! ")]), _c('BaseButton', {
    attrs: {
      "size": "medium",
      "border-radius": "1000px",
      "width": "100%",
      "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
      "right-svg-icon-color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" Kembali ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }